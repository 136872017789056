import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import styled from 'styled-components'

// Components
import TitleDefault from 'components/elements/TitleDefault'
import GravityForm from 'components/GravityForm'
import Image from 'components/elements/Image'

const StyledButton = styled.button`
  position: relative;
  background: ${(props) => props.theme.color.gradient.main};
  border-radius: 29.5px;
  display: inline-block;
  height: 45px;
  color: ${(props) => props.theme.color.text.light};
  font-weight: ${(props) => props.theme.font.weight.xl};
  width: 200px;
`

const StyledModal = styled(Modal)`
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 1020px;
  background: ${(props) => props.theme.color.text.dark};
`

function App({ image }) {
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div>
      <StyledButton onClick={openModal}>Download brochure</StyledButton>
      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div>
          <div className="text-right">
            <button onClick={closeModal} className="text-white p-3">
              X
            </button>
          </div>
          <div className="sc-fznZeY kcXlwt row px-4 pb-5 m-0 align-items-center">
            <div className="col-lg-7 pl-5">
              <TitleDefault className="mb-4 text-white font-size-l">
                Download onze brochure
              </TitleDefault>
              <GravityForm id={3} brochure className="whiteLabel" />
            </div>
            <div className="col-lg-1 d-none d-lg-block" />
            <div className="col-lg-4">
              <Image src={image} />
            </div>
          </div>
        </div>
      </StyledModal>
    </div>
  )
}

export default App
